import dayjs from 'dayjs'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import { CommissioningSessionInterface } from '../../../../../domain/Commissioning/CommissioningList'
import {
  ActionItemInterface,
  ActionList,
  ActionListInterface,
} from '../../../../../domain/Commissioning/CommissioningSessionAction'
import { ValidationErrorInterface } from '../../../../../domain/Error/Error'
import CommissioningSessionGateway from '../../../../../gateway/Commissioning/CommissioningSessionGateway'
import { setCommissionActions, setRefreshCommission } from '../../../../store/component/commission'
import { setOpenCommissionControlListForm } from '../../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { handleCloseToast, toastError, toastProcess, toastSuccess } from '../../../util/Toast'
import InputDate from '../../Elements/InputDate'

interface Props {
  register: any
  control: any
  getValues: any
  setValue: any
  sessionId: string
  uuidRefresh: string
  commissioning: CommissioningSessionInterface | undefined
  setFormErrors: React.Dispatch<React.SetStateAction<ValidationErrorInterface | null>>
}

export const ACTIONS = {
  CALCULATE: 'calcul',
  CONTROL: 'control',
  VERIFY: 'verify',
  VALIDATE: 'validate',
  INVALIDATE: 'invalidate',
  CANCEL: 'cancel',
}

const ACTION_NAME_RECALCULATE = 'recalcul'

const Actions: FunctionComponent<Props> = ({
  register,
  control,
  getValues,
  setValue,
  sessionId,
  uuidRefresh,
  commissioning,
  setFormErrors,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const commissionActions = useAppSelector(state => state.commission.actions)
  const [actionArray, setActionArray] = useState<ActionListInterface>()
  const [uuidRefreshAction, setUuidRefreshAction] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [firstRender, setFirstRender] = useState<boolean>(true)
  const commission = useAppSelector(state => state.commission)

  useEffect(() => {
    if (!firstRender) {
      new CommissioningSessionGateway().getActions(sessionId).then(actions => dispatch(setCommissionActions(actions)))
    }

    setFirstRender(false)
  }, [uuidRefresh, uuidRefreshAction])

  useEffect(() => {
    if (commissionActions) {
      let data = {} as ActionListInterface

      Object.keys(commissionActions).map(key => {
        data = {
          ...data,
          [key as keyof ActionListInterface]: { ...commissionActions[key as keyof ActionListInterface] },
        }
      })

      setActionArray(data)
    }
  }, [commissionActions])

  useEffect(() => {
    if (actionArray) {
      Object.entries(actionArray).map((action: [string, ActionItemInterface]) => {
        setValue(action[0] as keyof ActionListInterface, action[1])
      })
    }
  }, [actionArray])

  const setAction = (action: string) => {
    if (ACTIONS.CONTROL === action && !commission.isSelectedAllControl) {
      dispatch(
        setOpenCommissionControlListForm({
          show: true,
          checkLists: commission.controlLists,
          commissioning: commissioning,
        })
      )

      return
    }

    if (
      [
        ACTIONS.CALCULATE,
        ACTIONS.CONTROL,
        ACTIONS.VERIFY,
        ACTIONS.VALIDATE,
        ACTIONS.INVALIDATE,
        ACTIONS.CANCEL,
      ].includes(action)
    ) {
      setIsLoading(true)
      const toastId = toastProcess(t('commissioning.notify.calculate-process'))

      let date = getValues()[action as keyof ActionListInterface].date
      const reason = getValues()[action as keyof ActionListInterface].reason

      if (!date) {
        date = dayjs(new Date()).format('DD/MM/YYYY')
      }

      new CommissioningSessionGateway()
        .setAction(sessionId, action, date, reason)
        .then((response: ActionList | any) => {
          setFormErrors({})
          if (response) {
            dispatch(setCommissionActions(response))
            dispatch(setRefreshCommission(uuidV4()))
            toastSuccess(t('commissioning.notify.update-success'))
            return
          }
          toastError(t('commissioning.notify.update-error'))
        })
        .catch(e => {
          if (400 === e?.code && 'string' === typeof e?.messageApi) {
            toastError(e.messageApi)
          } else {
            if (e?.data?.errors && 'data-validation' === e?.data?.type) {
              setFormErrors(e?.data)
              if (e?.data?.title) {
                toastError(e?.data?.title)
              }
            }
            if (!e?.data?.title) {
              toastError(t('commissioning.notify.update-error'))
            }
          }
        })
        .finally(() => {
          handleCloseToast(toastId)
          setIsLoading(false)
        })
    }
  }

  const setRecalculate = () => {
    setIsLoading(true)
    const toastId = toastProcess(t('commissioning.notify.recalculate-process'))
    new CommissioningSessionGateway()
      .setRecalculate(sessionId)
      .then(response => {
        if (null !== response) {
          toastSuccess(t('commissioning.notify.update-success'))
          setUuidRefreshAction(uuidV4())
          return
        }

        toastError(t('commissioning.notify.update-error'))
      })
      .catch(e => {
        toastError(t('commissioning.notify.update-error'))
      })
      .finally(() => {
        handleCloseToast(toastId)
        setIsLoading(false)
      })
  }

  const isValidated = useMemo(() => {
    if (actionArray && ACTIONS.VALIDATE in actionArray) {
      return !!actionArray[ACTIONS.VALIDATE as keyof typeof actionArray]?.date
    }

    return false
  }, [actionArray])

  const disabledAction = ({ action, forInput = false }: { action: [string, any]; forInput?: boolean }): boolean => {
    return (
      isLoading ||
      !(action[1].enabled || (!forInput && action[0] === ACTIONS.CALCULATE && !isValidated && action[1]?.date))
    )
  }

  const handleClickAction = (action: [string, any]) => {
    if (action[0] === ACTIONS.CALCULATE && action[1]?.date) {
      setRecalculate()
      return
    }

    setAction(action[0])
  }

  const getActionTitle = (action: [string, any]) => {
    if (action[0] === ACTIONS.CALCULATE && action[1]?.date) {
      return ACTION_NAME_RECALCULATE
    }

    return action[0]
  }

  return (
    <div className='col-md-6'>
      <div className='form-bloc form-bloc--action'>
        <div className='form-bloc__title'>{t('commissioning.form.setting.actions-editions.title-action')}</div>
        <div className='form-bloc__form flex-container'>
          {actionArray &&
            Object.entries(actionArray).map((action: [string, any]) => {
              return (
                <div
                  key={uuidV4()}
                  className={`col-md-6 actions-item ${disabledAction({ action }) && 'disabled-content'}`}
                >
                  <button type='button' className='button button--white' onClick={() => handleClickAction(action)}>
                    {t(`commissioning.form.setting.actions-editions.${getActionTitle(action)}`)}
                  </button>
                  <InputDate
                    register={register}
                    control={control}
                    type={'text'}
                    name={`${action[0]}.date`}
                    id={`${action[0]}`}
                    disabled={disabledAction({ action, forInput: true })}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Actions
