import React, { FunctionComponent, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import RecurrenceSessionGateway from '../../../../../gateway/Recurrence/RecurrenceSessionGateway'
import { useAppSelector } from '../../../../store/hook'
import downloadBlobFile from '../../../util/DownloadBlobFile'
import { usePrompt } from '../../../util/Navigation'
import { toastError, toastSuccess } from '../../../util/Toast'
import ProductDatalist, { ProductDalalistItem } from '../../Datalist/Product/ProductDalalist'
import ControlledDatePicker from '../../Elements/ControlledDatePicker'
import Input from '../../Elements/Input'
import InputNumber from '../../Elements/InputNumber'
import SelectCustom from '../../Elements/Select'
import Footer from '../../Footer/Footer'
import ConfirmationModal from '../../Modal/ConfirmationModal'
import EditionAndDownload, { TEditionDownloadItem } from '../Blocks/EditionAndDownload'
import ActionsForm from '../Blocks/Recurrence/ActionsForm'
import SessionSetting from '../Blocks/Recurrence/SessionSetting/SessionSetting'
import { ISettingForm, TFieldErrors, TProps } from './types'

const Setting: FunctionComponent<TProps> = ({ recurrences, isLectureMode }) => {
  const { t } = useTranslation()
  const { sessionType, uuid } = useParams()
  const navigate = useNavigate()

  const referential = useAppSelector(state => state.referential.referential) as ReferentielInterface | null
  const openDatalistFilterRecurrenceSession = useAppSelector(state => state.event.openDatalistFilterRecurrenceSession)

  const [fieldErrors, setFieldErrors] = useState<TFieldErrors>({})
  const [isLocked, setIsLocked] = useState<boolean>(
    ['validated', 'executed', 'invalid', 'cancelled'].includes(recurrences?.status ?? 'draft')
  )
  const [uuidRefresh, setUuidRefresh] = useState<string>('')

  const { register, control, handleSubmit, reset, getValues, watch, setValue } = useForm<ISettingForm>({
    defaultValues: {
      session: {
        code: recurrences?.reference || '',
        status:
          referential?.recurrence?.session_status?.find(status => status.value === recurrences?.status)?.label || '',
        label: recurrences?.label || '',
        subscriptionAt: recurrences?.subscriptionAt || '',
      },
      actions: {
        process: '',
        cancel: '',
        control: '',
        invalidate: '',
        validate: '',
        verify: '',
      },
      editions: {
        transfer: '',
        syntheses: '',
      },
      sessionCriteria: {
        month: recurrences?.month || '',
        periodicity: recurrences?.periodicity || '',
        year: recurrences?.year || '',
        product: {
          id: recurrences?.product?.id,
          label: recurrences?.product?.label,
          value: recurrences?.product?.code,
          subscription_value: recurrences?.product?.subscription_value,
        },
        share_price: recurrences?.subscriptionPrice || 0,
      },
    },
  })

  const { isDirty } = useFormState({ control })

  const watchProduct = watch('sessionCriteria.product')
  const watchYear = watch('sessionCriteria.year')
  const watchMonth = watch('sessionCriteria.month')
  const watchSubscriptionAt = watch('session.subscriptionAt')
  const watchSharePrice = watch('sessionCriteria.share_price')

  const editionArray: TEditionDownloadItem[] = [
    { type: 'transfer', title: 'recurrence.form.setting.actions-editions.transfer' },
    { type: 'syntheses', title: 'recurrence.form.setting.actions-editions.syntheses' },
  ]

  const checkParameters = (): boolean => {
    const errors: TFieldErrors = {}
    let success = true

    if (!watchProduct.id) {
      errors['product'] = t('common.required.select.product')
      success = false
    }
    if (!watchYear) {
      errors['year'] = t('common.required.date.year')
      success = false
    }
    if (!watchMonth) {
      errors['month'] = t('common.required.date.month')
      success = false
    }
    if (!watchSubscriptionAt) {
      errors['subscriptionAt'] = t('common.required.date.full')
      success = false
    }
    if (!watchSharePrice) {
      errors['sharePrice'] = t('common.required.field')
      success = false
    }

    if (!success) {
      setFieldErrors(errors)
      return false
    }

    setFieldErrors({})
    return true
  }

  const handleConfirm = (data: ISettingForm) => {
    const type = sessionType ?? ''
    const gateway = new RecurrenceSessionGateway()

    const handleSuccess = (res: any) => {
      toastSuccess(t('recurrence.session.notify.update-success'))
      setUuidRefresh(uuidV4())
    }

    const handleError = (e: any) => {
      if (e.data?.errors[0]?.messages?.length) {
        // handle specific errors
        toastError(e.data?.errors[0]?.messages[0])
      } else {
        toastError(t('recurrence.session.notify.update-error'))
      }
    }

    if (uuid) {
      gateway.update(data, type, uuid).then(handleSuccess).catch(handleError)
    } else {
      gateway
        .create(data, type)
        .then(res => {
          toastSuccess(t('recurrence.session.notify.add-success'))
          navigate(`/${t('url.recurrence.session.edit-setting')}/${res.type}/${res.id}`)
        })
        .catch(handleError)
    }

    reset(data)
  }

  const handleSubmitForm: SubmitHandler<ISettingForm> = data => {
    if (!checkParameters()) return

    confirmAlert({
      customUI: ({ onClose }) => <ConfirmationModal onConfirm={() => handleConfirm(data)} onClose={onClose} />,
    })
  }

  const handleClickDownload = (item: TEditionDownloadItem) => {
    const sessionId = uuid
    const type = item.type
    const gateway = new RecurrenceSessionGateway()

    if (!sessionId) return

    const handleDownload = (response: any) => {
      if (response) {
        downloadBlobFile(response, t(`commissioning.settings.extract.${type}`))
      }
    }

    const handleError = (response: any) => {
      if (response.blob?.type === 'application/json') {
        toastError(response.blob, undefined, t('recurrence.notify.export-control-error'))
      } else {
        toastError(t('recurrence.notify.export-control-error'))
      }
    }

    if (type === 'syntheses') {
      gateway
        .getExport(sessionId, 'datas', openDatalistFilterRecurrenceSession.filters)
        .then(handleDownload)
        .catch(handleError)
    } else {
      gateway
        .getExport(sessionId, type, openDatalistFilterRecurrenceSession.filters)
        .then(handleDownload)
        .catch(handleError)
    }
  }

  const fetchSubscriptionValue = (product: ProductDalalistItem) => {
    if (product?.id) {
      setValue('sessionCriteria.share_price', product.subscription_value)
    }
  }

  usePrompt(!isLectureMode && isDirty, handleSubmit(handleConfirm))

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} className='flex-container'>
      <SessionSetting
        register={register}
        control={control}
        classes='col-md-12'
        parentPath='session.'
        title={t('recurrence.form.setting.session.title')}
        readOnly={isLocked}
        subscriptionAtError={fieldErrors.subscriptionAt}
      />

      <div className={uuid ? 'col-md-6' : 'col-md-12'}>
        <div className='form-bloc'>
          <div className='flex form-bloc__title justify-between items-center'>
            <span>{t('recurrence.form.setting.session.session-criteria-title')}</span>
          </div>
          <div className='form-bloc__form flex-container'>
            <Input
              register={register}
              classes='col-md-6'
              label={t('recurrence.form.setting.session.name')}
              type='text'
              name='session.label'
              id='session.label'
              readOnly={isLectureMode}
              required
              errorMessage={fieldErrors.label}
            />

            <SelectCustom
              classes='col-md-6'
              id='sessionCriteria.periodicity'
              name='sessionCriteria.periodicity'
              options={referential?.recurrence.session_periodicity || []}
              register={register}
              label={t('recurrence.form.setting.session.periodicity')}
              noChoiceOption
              required
              errorMessage={fieldErrors.periodicity}
              readOnly={isLectureMode || isLocked}
            />

            <SelectCustom
              classes={isLectureMode ? 'col-md-6 form-control__readonly' : 'col-md-6'}
              id='sessionCriteria.month'
              name='sessionCriteria.month'
              options={referential?.recurrence.session_month || []}
              register={register}
              label={t('recurrence.form.setting.session.months-treatment')}
              noChoiceOption
              required
              errorMessage={fieldErrors.month}
              readOnly={isLectureMode || isLocked}
            />

            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='sessionCriteria.year' className='form-control__label mandatory-field'>
                  {t('recurrence.form.setting.session.years-treatment')}
                </label>
                <div className='form-control__input'>
                  <ControlledDatePicker
                    control={control}
                    name='sessionCriteria.year'
                    dateFormat='yyyy'
                    dateFormatCalendar='yyyy'
                    minDate={new Date(2022, 1, 1)}
                    maxDate={new Date(`31/12/${new Date().getFullYear() + 10}`)}
                    required
                    customInput={<InputMask mask='9999' maskPlaceholder='____' alwaysShowMask required />}
                    showYearPicker
                    errorMessage={fieldErrors.year}
                    readOnly={isLectureMode || isLocked}
                  />
                </div>
              </div>
            </div>

            <ProductDatalist
              classes='col-md-6'
              id='sessionCriteria.product'
              name='sessionCriteria.product'
              label={t('movement.redemption-withdrawal.form.general-information.product-acronym')}
              labelClasses='mandatory-field'
              control={control}
              defaultValue={getValues('sessionCriteria.product')}
              disabled={isLectureMode || isLocked}
              errorMessage={fieldErrors.product}
              customOnchange={fetchSubscriptionValue}
            />

            <InputNumber
              label={t('recurrence.form.setting.session.share_price')}
              id={'sessionCriteria.share_price'}
              name={'sessionCriteria.share_price'}
              classes={'col-md-6'}
              readOnly={isLectureMode || isLocked}
              control={control}
              decimalScale={2}
              register={register}
              errorMessage={fieldErrors.sharePrice}
            />
            {/*
            <Input
              register={register}
              classes="col-md-6"
              label={t('recurrence.form.setting.session.share_price')}
              type="number"
              name="sessionCriteria.share_price"
              id="sessionCriteria.share_price"
              required
              readOnly={isLectureMode || isLocked}
              
            />
            */}
          </div>
        </div>
      </div>

      {uuid && (
        <ActionsForm
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          sessionId={uuid}
          uuidRefresh={uuidRefresh}
          recurrences={recurrences}
        />
      )}

      {uuid && (
        <EditionAndDownload
          title={t('commissioning.form.setting.actions-editions.title-edition')}
          register={register}
          control={control}
          editionArray={editionArray}
          sessionId={uuid}
          parentPath='editions.'
          classes='col-md-12'
          onClick={handleClickDownload}
        />
      )}

      <Footer
        isLectureMode={isLectureMode}
        disabled={isLectureMode}
        onCancelUrl={t('url.recurrence.session.dashboard')}
      />
    </form>
  )
}

export default Setting
